import React from 'react'

import Results from './components/results-bubble'

import { requireRegionFile } from 'services/region-loader'

const common_steps = requireRegionFile('steps.json')
const states_steps = requireRegionFile('states_steps.json')
const municipalities_steps = requireRegionFile('municipalities_steps.json')
const parishes_steps = requireRegionFile('parishes_steps.json')

const steps = common_steps.concat(states_steps, municipalities_steps, parishes_steps)
const resultsStep = { id: 'showResults', component: <Results />, end: true }

export default [...steps, resultsStep]
