import React from 'react'
import { useTranslation } from 'react-i18next'

export interface Props {
  classes: string[]
}



const parseLevel = (classes: any, postfix: any) => {
  const regexp = new RegExp(`\\w+${postfix}`)
  const termArray = classes.filter(c => c.match(regexp))
  if (termArray.length === 0) {
    return ''
  }
  return termArray[0].replace(postfix, '')
}

export const Recommendations: React.FC<Props> = ({ classes }) => {
  const { t } = useTranslation()

  const medicalRisk = parseLevel(classes, "-medical-risk")
  const infectionlRisk = parseLevel(classes, "-infection-risk")
  let items = []
  if (infectionlRisk !== '') {
    items.push(`El riesgo de que tengas COVID-19 es: ${t(infectionlRisk)}.`)
  }
  if (medicalRisk !== '') {
    items.push(`El riesgo de que tengas una infección severa es: ${t(medicalRisk)}.`)
  }
  if (medicalRisk === 'low' && infectionlRisk === 'high') {
    items.push('Contacta a tu médico de confianza cuanto antes.')
  }
  if (medicalRisk === 'high' && infectionlRisk === 'low') {
    items.push('Es indispensable que sepas que si presentas los síntomas de COVID-19 debes aislarte para evitar contagiar a otras personas y contactar a tu médico de confianza cuanto antes.')
  }
  if (medicalRisk === 'high' && infectionlRisk === 'high') {
    items.push('Es indispensable que te aisles para que evites contagiar a otras personas.')
    items.push('Contacta a tu médico de confianza cuanto antes.')
  }
  if (medicalRisk === 'medium' && infectionlRisk === 'medium') {
    items.push('Mantente informado y contacta a tu médico de confianza en caso de presentar algún síntoma.')
  }
  const itemsList = items.map((text,i) => <li key={i}>{text}</li>)
  return <ul>{itemsList}</ul>
}

export default Recommendations
