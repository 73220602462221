export default {
  low: 'BAJO',
  medium: 'MODERADO',
  high: 'ALTO',
  resultsPage: {
    headerTitle: 'COVID-19: Recomendaciones',
    noResultsMessage: 'Resultados no encontrados en URL.',
    changeAudienceTitle: '¿No aplica a tu caso?',
    changeAudience: 'Intenta de nuevo',
    audiencePrefix: '¡Gracias por responder!',
    lastModified: 'Actualizado {{date}}',
  },
  share: {
    viewResults: 'Conoce cuál es tu riesgo de infección',
    twitterButton: 'Comparte en Twitter',
    facebookButton: 'Comparte en Facebook',
    twitterShareText:
      'Información oficial y actualizada sobre COVID-19 %23coronavirus {{url}}',
    CTA: 'Comparte la información, no el virus.'
  },
  welcomePage: {
    title: 'COVID-19: Recomendaciones',
    description:
    'Obten información precisa y personalizada de fuentes médicas venezolanas confiables sobre COVID-19',
    button: 'Empezar'
  },
  classes: {
    common: 'todos los Venezolanos'
  },
  footer: {
    aboutHeader: 'Acerca de',
    aboutMenu: [
      ['Acerca de', 'https://github.com/dialoguemd/covid-19/wiki'],
      ['Proyecto de GitHub', 'https://github.com/dialoguemd/covid-19'],
      ['Dialogue', 'https://dialogue.co/es'],
      [
        'Recursos para Organizaciones Venezolanas',
        'https://www.dialogue.co/?hs_preview=noJtvihk-26668052747'
      ],
      ['Contact Dialogue', 'https://www.dialogue.co/en/contact-us/']
    ],
    adminAreaHeader: 'Para Residentes',
    classHeader: 'Para Venezolanos',
    classMenu: [
      ['Información General', 'common'],
      ['Personas a alto riesgo', 'elevated-covid-risk'],
      [
        'Personas a riesgo de complicaciones',
        'elevated-medical-risk'
      ],
      ['Personas con planes de viaje', 'travel-plans']
    ]
  },
  provinces: {
    AMA: "Amazonas",
    ANZ: "Anzoátegui",
    APU: "Apure",
    ARA: "Aragua",
    BAR: "Barinas",
    CAR: "Carabobo",
    COJ: "Cojedes",
    DAM: "Delta Amacuro",
    DTC: "Distrito Capital",
    FAL: "Falcón",
    GUA: "Guárico",
    LAR: "Lara",
    MER: "Mérida",
    MIR: "Miranda",
    MON: "Monagas",
    NEP: "Nueva Esparta",
    POR: "Portuguesa",
    SUC: "Sucre",
    TAC: "Táchira",
    TRU: "Trujillo",
    VAR: "Vargas",
    YAR: "Yaracuy",
    ZUL: "Zulia"
  }
}
