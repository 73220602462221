import { Engine } from 'json-rules-engine'
import { Buffer } from 'buffer'
import { requireRegionFile } from 'services/region-loader'
const rules = requireRegionFile('rules.json')

export const isRule = rule =>
  typeof rule === 'object' && !!rule.conditions && !!rule.event

export const isDefined = value => value !== undefined

export const stepHasValue = step => isDefined(step.value)

export const getRulesFromChatSteps = (steps = []) =>
  steps.map(step => step && step.metadata && step.metadata.rule).filter(isRule)

export const getFactsFromChatSteps = (steps = []) =>
  steps
    .filter(stepHasValue)
    .reduce((facts, step) => ({ ...facts, [step.id]: step.value }), {})

export const getClassesFromRuleResults = ({ events }) =>
  events
    .flatMap(event => event && event.params && event.params.classes)
    .filter(isDefined)

export const getChatClassesFromSteps = steps => {
  const answeredSteps = Object.values(steps).filter(
    (step: any) => step.value !== undefined
  )

  // TODO: Check if there's a way to clear rule set and reuse instance
  // One-time use.
  let engine = new Engine()

  // Load rules defined in global rule set
  rules.forEach(rule => engine.addRule(rule))

  // Load rules defined on steps
  const stepRules = getRulesFromChatSteps(answeredSteps)
  stepRules.forEach(rule => engine.addRule(rule))

  // Extract facts from the chat values
  const facts = getFactsFromChatSteps(answeredSteps)

  const cleanUp = () => {
    // Clean up
    engine = null
  }

  // Run the engine to evaluate, return promise
  return engine
    .run(facts)
    .then(getClassesFromRuleResults)
    .finally(cleanUp)
}

const weights = new Map();
weights.set("askHasHadContactOptions", 3)
weights.set("askHasFeverOptions", 6)
weights.set("askHasCoughOptions", 6)
weights.set("askHasDifficultyBreathingOptions", 4)
weights.set("askHasMusclePainsOptions", 2)
weights.set("askHasJointPainOptions", 1)
weights.set("askHasShiversOptions", 1)
weights.set("askHasRunnyNoseOptions", -3)
weights.set("askTraveledAffectedAreasOptions", 3)
weights.set("askHasRangeOptions", 2)
weights.set("askHasImmuneDecreasedOptions", 2)
weights.set("askHasImmuneDecreased2Options", 2)
weights.set("askHasChronicLungDiseaseOptions", 2)


const getInfectionRisk = score => {
  if (score > 20) {
    return "high"
  }
  if (score > 10) {
    return "medium"
  }
  return "low"
}

const getMedicalRisk = score => {
  if (score > 6) {
    return "high"
  }
  if (score > 4) {
    return "medium"
  }
  return "low"
}

const medicalRiskStepNames = [
  "askHasRangeOptions",
  "askHasImmuneDecreasedOptions",
  "askHasImmuneDecreased2Options",
  "askHasChronicLungDiseaseOptions"
]

const infectionRiskStepNames = [
  "askHasHadContactOptions",
  "askHasFeverOptions",
  "askHasCoughOptions",
  "askHasDifficultyBreathingOptions",
  "askHasMusclePainsOptions",
  "askHasJointPainOptions",
  "askHasShiversOptions",
  "askHasRunnyNoseOptions",
  "askTraveledAffectedAreasOptions"
]

const getRiskScore = (answeredSteps, stepNames) => {
  const riskScore = answeredSteps.reduce((acc, step: any) => {
    if (stepNames.includes(step.id) && step.value && weights.get(step.id) !== undefined) {
      return acc + weights.get(step.id)
    }
    return acc
  }, 0)
  return riskScore
}

export const getScoresFromSteps = steps => {
  const answeredSteps = Object.values(steps).filter(
    (step: any) => step.value !== undefined
  )
  const medicalRiskScore = getRiskScore(answeredSteps, medicalRiskStepNames)
  const infectionRiskScore = getRiskScore(answeredSteps, infectionRiskStepNames)

  const infectionRisk = `${getInfectionRisk(infectionRiskScore)}-infection-risk`
  const medicalRisk = `${getMedicalRisk(medicalRiskScore)}-medical-risk`

  const scores = {
    infectionRisk: infectionRisk,
    medicalRisk: medicalRisk,
    medicalRiskScore: medicalRiskScore,
    infectionRiskScore: infectionRiskScore
  }
  return scores
}

const angosturaMapping = new Map();
angosturaMapping.set("askForLocationOptions", "codigo_estado")
angosturaMapping.set("askHasHadContactOptions", "contacto-covid19")
angosturaMapping.set("askHasFeverOptions", "fiebre")
angosturaMapping.set("askHasCoughOptions", "tos")
angosturaMapping.set("askHasDifficultyBreathingOptions", "dificultad-respirar")
angosturaMapping.set("askHasMusclePainsOptions", "mialgia")
angosturaMapping.set("askHasJointPainOptions", "artralgia")
angosturaMapping.set("askHasShiversOptions", "escalofrios")
angosturaMapping.set("askHasRunnyNoseOptions", "rinorrea")
angosturaMapping.set("askTraveledAffectedAreasOptions", "viajes-internacionales")
angosturaMapping.set("askHasRangeOptions", "mayor-65")
angosturaMapping.set("askHasImmuneDecreasedOptions", "inmunidad")
angosturaMapping.set("askHasImmuneDecreased2Options", "med-inmune")
angosturaMapping.set("askHasChronicLungDiseaseOptions", "historia-pulmon")


export const sendDataToAngostura = async (medicalRiskScore, infectionRiskScore, steps) => {
  const answeredSteps = Object.values(steps).filter(
    (step: any) => step.value !== undefined
  )
  const raw_payload:any = {}
  raw_payload.user = {source: "web"}
  raw_payload.riesgo_medico = medicalRiskScore
  raw_payload.riesgo_covid = infectionRiskScore
  raw_payload.riesgo_total = medicalRiskScore + infectionRiskScore
  raw_payload.pais = "Venezuela"

  answeredSteps.forEach( (step: any) => {
    if (angosturaMapping.get(step.id)) {
      let propValue: any
      if (typeof step.value === 'string' || step.value instanceof String) {
        propValue = step.value
      }
      if (typeof step.value === 'boolean' || step.value instanceof Boolean) {
        propValue = step.value === true ? "Si" : "No"
      }
      raw_payload[angosturaMapping.get(step.id)] = propValue
    } else if (/askForMunicipalityOptions/.test(step.id)) {
      raw_payload["codigo_municipio"] = step.value
    } if (/askForParishOptions/.test(step.id)) {
      raw_payload["codigo_parroquia"] = step.value
    }
  })

  const objJsonStr = JSON.stringify(raw_payload)
  const objJsonB64 = Buffer.from(objJsonStr).toString("base64")

  const devUrl = "https://us-central1-event-pipeline.cloudfunctions.net/dev-angosturagate"
  const prodUrl = "https://us-central1-event-pipeline.cloudfunctions.net/prod-angosturagate"

  const angosturaPayload = {
    type: "covid_bot_diagnostic",
    version: "1",
    payload: objJsonB64
  };

  for (const url of [devUrl, prodUrl]) {
    await fetch(url, {
      method: "POST",
      body: JSON.stringify(angosturaPayload),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    }).then(res => console.log(res));
  }
}
