import { requireRegionFile } from 'services/region-loader'

const states = requireRegionFile('i18n/states.json')
const municipalities = requireRegionFile('i18n/municipalities.json')
const parishes = requireRegionFile('i18n/parishes.json')

const locals = {
  intro1: '¡Hola!',
  intro2:
    "Soy un asistente automatizado creado conjuntamente por Médicos por la Salud y Code for Venezuela. Estoy aquí para brindarte información precisa sobre COVID-19. Para que sea relevante para tu situación, me gustaría hacerte algunas preguntas. No debería tomar más de unos minutos.",
  intro3:
    " Ten en cuenta que no recibirás una evaluación médica. Si tienes síntomas graves, busca atención en un centro de salud. Este servicio no sustituye la consulta con tu médico.",
  'intro-option1': "¡Listo, empecemos!",
  'askHasHadContactOptions-Option1': 'Sí, algo de esto aplica a mi',
  'askHasImmuneDecreasedOptions-Option1': 'Sí, algo de esto aplica a mi',
  'askHasImmuneDecreased2Options-Option1': 'Sí',
  'askHasImmuneDecreasedOptions-Option2': 'No, nada de esto aplica a mi',
  yes: 'Sí',
  no: 'No',
  askForLocation: '¿En qué Estado del país te encuentras?',
  askForMunicipalityInfo: '¿En que Municipio?',
  askForParishInfo: '¿En que Parroquia?',
  askHasHadContact: '¿Has estado en **contacto cercano** con un caso confirmado o probable de COVID-19?',
  askHasHadContactInfo: `
  Por **contacto cercano** quiero decir **cualquiera** de las siguientes situaciones:

  - Exposición relacionada con la atención médica, como brindar atención a pacientes infectados, visitarlos o quedarse con ellos en el mismo entorno cercano
  - Trabajar cerca o compartir el mismo aula con una persona infectada.
  - Viajar con una persona infectada.
  - Vivir en el mismo hogar que una persona infectada
  `,
  askOptionUnsure: `No lo sé`,
  askAgeRange: `¿Eres mayor de 65 años?`,
  askHasFever: `¿Has tenido fiebre (más de 37.8ºC) en las últimas 24 horas?`,
  askHasCough: `¿Tienes tos?`,
  askHasMusclePains: `¿Tienes dolores musculares?`,
  askHasJointPain: `¿Tienes dolor en las articulaciones?`,
  askHasShivers: `¿Tienes escalofríos?`,
  askHasRunnyNose: `¿Tienes secreción nasal abundante or ardor nasal?`,
  askHasDifficultyBreathing: `¿Tienes dificultad para respirar?`,
  askTraveledAffectedAreas: `¿Has viajado fuera del país en los últimos 14 días? `,
  askHasImmuneDecreased: `Ahora me gustaría hacerte algunas preguntas para saber si tu sistema inmune está debilitado .`,
  askHasImmuneDecreasedInfo: `
  ¿** Alguna ** de las siguientes opciones aplica en tu caso?

  - ¿Tienes antecedentes de diabetes?
  - ¿Tomas medicamentos inmunosupresores como Prednisona o cualquier otro esteroide?
  - ¿Tienes una enfermedad autoinmune como lupus, artritis reumatoide o enfermedad de Crohn?
  - ¿Tienes antecedentes de cáncer?
  - ¿Tienes VIH / SIDA?
  - ¿Tienes enfermedad renal o hepática crónica?
  - ¿Estas actualmente tomando medicamentos después de un trasplante de órgano?
  - ¿Te ha dicho tu médico que tu sistema inmunológico está comprometido?,
  `,
  askHasImmuneDecreased2: `¿Estás tomando algún medicamento que, según un médico, pueda debilitar tu sistema inmunológico?`,
  askHasChronicLungDisease: `¿Tienes antecedentes de una enfermedad pulmonar crónica?`,
  askHasChronicLungDiseaseInfo: `
  ¿No estás seguro? Responde **sí** si tiene un historial de **cualquiera** de lo siguiente:

  - Asma
  - Enfermedad pulmonar obstructiva crónica (EPOC) o enfisema
  - Bronquitis crónica
  - Cualquier enfermedad pulmonar crónica no mencionada anteriormente`,
  askHasTravelPlans: `
  ¿Estás planeando viajar fuera de Venezuela el próximo mes?`,
  outro1: `
  ¡Gracias por tus respuestas! Tu paquete de información personalizada está listo.
  `
}

let translations = Object.assign(locals, states, municipalities, parishes)

export default translations
